import React from 'react'
import get from 'lodash/get'
import { graphql } from 'gatsby'
import { DirectoryOfServicesLayout } from '../components/layouts'
class DirectoryOfServicesTemplatePTP extends React.Component {
    render() {
        const site = get(this.props, 'data.site.siteMetadata')
        const page = get(this.props, 'data.contentfulPageDirectoryOfServicesPtp')
        const location = get(this.props, 'location')
        let sitemap = get(this.props, 'data.allContentfulWidgetSitemapPtp.edges', [])
        sitemap = sitemap.map((link) => link && link.node)
        return <DirectoryOfServicesLayout title={`Power to Protect - ${page.title}`} site={site} page={page} sitemap={sitemap} location={location} className="ptp-root" />
    }
}
export default DirectoryOfServicesTemplatePTP

export const pageQuery = graphql`
    query DirectoryOfServicesBySlugPTP($slug: String!) {
        site {
            siteMetadata {
                title
                description
                siteUrl
                twitterHandle
            }
        }
        contentfulPageDirectoryOfServicesPtp(slug: { eq: $slug }) {
            __typename
            title
            slug
            userSection
            description {
                description
            }
            links {
                __typename
                ... on ContentfulPageGenericPtp {
                    id
                    slug
                    userSection
                    title
                }
                ... on ContentfulPageGroupPtp {
                    id
                    slug
                    userSection
                    title
                }
                ... on ContentfulPageWidgetsPtp {
                    id
                    slug
                    userSection
                    title
                }
                ... on ContentfulPageDirectoryOfServicesPtp {
                    id
                    userSection
                    title
                    slug
                }
                ... on ContentfulWidgetLinkPtp {
                    id
                    text
                    url
                }
            }
        }
        allContentfulWidgetSitemapPtp(filter: { title: { ne: "Don't delete - sitemap" } }) {
            edges {
                node {
                    contentful_id
                    userSection
                    title
                    slug
                    links {
                        __typename
                        ... on ContentfulPageDirectoryOfServicesPtp {
                            id
                            title
                        }
                        ... on ContentfulPageGenericPtp {
                            slug
                            title
                            userSection
                        }
                        ... on ContentfulPageGroupPtp {
                            slug
                            title
                            userSection
                        }
                        ... on ContentfulPageWidgetsPtp {
                            title
                            slug
                            userSection
                        }
                    }
                }
            }
        }
    }
`
